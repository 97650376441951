import React from 'react';
import './App.css';
// import detectEthereumProvider from '@metamask/detect-provider';

// const provider = await detectEthereumProvider();
//     console.log(provider);
//     if (provider) {
//       return;
//     }
//     // @ts-ignore
//     const ethereum = provider.ethereum;
//     ethereum.request({ method: 'eth_requestAccounts' });

import Web3 from 'web3';

const web3 = new Web3(Web3.givenProvider);

function App() {
  const num = 123213;

  const onPurchaseClick = async () => {
    // @ts-ignore
    await web3.eth.requestAccounts();
    console.log();
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          SOON
        </p>
        {/* <h1>{num.toString(16)}</h1>
        <button onClick={onPurchaseClick}>Make Yours</button> */}
      </header>
    </div>
  );
}

export default App;
